import { API_URL } from '../config';

export const API_URI = {
  RECENT: 'recent',
  NEW_MENU: 'new-menu',
  EVENT: 'event',
  NOTICE: 'notice',
  STORE: 'store',
  FAQ: 'faq',
  SUGGEST: 'suggest',
  VOC: 'voc',
  MEMBER: 'member',
  USER: 'user',
  POINT: 'point',
  STAMP: 'stamp',
  COUPON: 'coupon',
  CARD: 'card',
};

/****************************************************************
 * Common HTTP GET method
 ****************************************************************/
export const get = async uri => {
  try {
    const response = await fetch(`${API_URL}/${uri}`, {
      method: 'GET',
    });

    return response.json();
  } catch (error) {
    console.error(error.message);
  }
};

/****************************************************************
 * Common HTTP POST method
 ****************************************************************/
export const post = async (uri, params) => {
  try {
    const response = await fetch(`${API_URL}/${uri}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    return response.json();
  } catch (error) {
    console.error(error.message);
  }
};

export const postFormData = async (uri, formData) => {
  try {
    const response = await fetch(`${API_URL}/${uri}`, {
      method: 'POST',
      body: formData,
    });

    return response.json();
  } catch (error) {
    console.error(error.message);
  }
};

/****************************************************************
 * 로그인
 ****************************************************************/
export const login = code => {
  return post('auth/token', {
    code,
  });
};

/****************************************************************
 * 로그아웃
 ****************************************************************/
export const logout = () => {
  return post('auth/revoke', {
    token: sessionStorage.getItem('access_token'),
  });
};

/****************************************************************
 * 회원탈퇴
 ****************************************************************/
export const withdraw = ticket => {
  return post('member/withdraw', {
    token: sessionStorage.getItem('access_token'),
    ticket,
  });
};

/****************************************************************
 * 마케팅정보수신동의변경
 ****************************************************************/
export const updatePromotionAllowed = (isAllowedSmsPromotion, isAllowedEmailPromotion) => {
  return post('member/update', {
    token: sessionStorage.getItem('access_token'),
    member: {
      isAllowedSmsPromotion,
      isAllowedEmailPromotion,
    },
  });
};

/****************************************************************
 * 주소변경티켓요청
 ****************************************************************/
export const getChangeAddressTicket = () => {
  return post('member/getTicket/changeAddress', {
    token: sessionStorage.getItem('access_token'),
    callbackUrl: `${window.location.origin}/callback/web/change-address/`,
    popupYn: 'Y',
  });
};

/****************************************************************
 * 휴대전화번호변경티켓요청
 ****************************************************************/
export const getChangeCellphoneTicket = () => {
  return post('member/getTicket/changeCellphone', {
    token: sessionStorage.getItem('access_token'),
    callbackUrl: `${window.location.origin}/callback/web/change-cellphone/`,
    popupYn: 'Y',
  });
};

/****************************************************************
 * 비밀번호변경티켓요청
 ****************************************************************/
export const getChangePasswordTicket = () => {
  return post('member/getTicket/changePassword', {
    token: sessionStorage.getItem('access_token'),
    callbackUrl: `${window.location.origin}/callback/web/change-password/`,
  });
};

/****************************************************************
 * 비밀번호확인티켓요청
 ****************************************************************/
export const getConfirmPasswordTicket = type => {
  return post('member/getTicket/confirmPassword', {
    token: sessionStorage.getItem('access_token'),
    callbackUrl: `${window.location.origin}/callback/web/${type}/`,
  });
};

/****************************************************************
 * 비밀번호확인티켓유효성요청
 ****************************************************************/
export const getVerifyTicket = ticket => {
  return post('member/verifyTicket', {
    token: sessionStorage.getItem('access_token'),
    ticket,
  });
};

/****************************************************************
 * 카드명변경
 ****************************************************************/
export const changeCardNickName = (cardNo, newNickName) => {
  return post('card/changeNickName', {
    token: sessionStorage.getItem('access_token'),
    cardNo,
    newNickName,
  });
};

/****************************************************************
 * 카드상태변경
 ****************************************************************/
export const changeCardStatus = body => {
  return post('card/changeStatus', {
    token: sessionStorage.getItem('access_token'),
    ...body,
  });
};

/****************************************************************
 * 카드등록
 ****************************************************************/
export const registCard = (cardNo, cardPinNo, cardNickName) => {
  return post('card/regist', {
    token: sessionStorage.getItem('access_token'),
    cardNo,
    cardPinNo,
    cardNickName,
  });
};
