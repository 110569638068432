import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { navigate, Link } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import Ionicon from 'react-ionicons';

import styles from './Desktop.module.scss';
import { PAYCO_GIFTSHOP_URL } from '../../../config';
import { post } from '../../../api';

const cx = classNames.bind(styles);

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isScroll: false,
      isLoggedIn: false,
      memberNo: null,
      showPanel: false,
    };

    this._handleScroll = this._handleScroll.bind(this);
  }

  componentDidMount() {
    const token = sessionStorage.getItem('access_token');

    // 엑세스 토큰 있는 경우
    if (token !== null) {
      post('member/me/sign', { token }).then(result => {
        if (result.isSuccess) {
          this.setState({
            isLoggedIn: true,
            memberNo: result.data.idNo,
          });
        }
      });
    }

    window.addEventListener('scroll', this._handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll);
  }

  render() {
    const { isOpen, isScroll } = this.state;
    const { menus, isTransparent } = this.props;

    const logoImage =
      isTransparent && !isOpen && !isScroll
        ? require('assets/images/logo/logo-white.png')
        : require('assets/images/logo/logo-black.png');

    return (
      <header
        className={cx(['header', { transparent: isTransparent && !isOpen && !isScroll, white: isOpen || isScroll }])}>
        <div className={cx(['container', { open: isOpen }])}>
          <Container>
            <Row>
              <Col xs={12}>
                <div className={cx('wrapper')}>
                  <Link to="/" className={cx('logoWrapper')}>
                    <img src={logoImage} alt="logo" className={cx('logo')} />
                  </Link>
                  <div className={cx('menuWrapper')}>
                    <div
                      className={cx(['menus', { transparent: isTransparent && !isOpen && !isScroll }])}
                      onMouseEnter={() => this.toggleMenu(true)}
                      onMouseLeave={() => this.toggleMenu(false)}>
                      {menus.map((menu, i) => {
                        return (
                          <div key={i} className={cx('menu')}>
                            <div className={cx('title')}>{menu.title}</div>
                            <div className={cx('submenus')}>
                              {menu.submenus.map((submenu, idx) => {
                                if (submenu.newWindow) {
                                  return (
                                    <a
                                      key={idx}
                                      className={cx('title')}
                                      href={submenu.uri}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      {submenu.title}
                                    </a>
                                  );
                                } else if (submenu.uri) {
                                  return (
                                    <div
                                      key={idx}
                                      className={cx('title')}
                                      onClick={this._onClick.bind(this, submenu.uri)}>
                                      {submenu.title}
                                    </div>
                                  );
                                } else if (submenu.title === '선물하기') {
                                  return (
                                    <div key={idx} className={cx('title')} onClick={this._onClickGiftshop.bind(this)}>
                                      {submenu.title}
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {this.state.showPanel && (
          <div className={cx('loginOverlay')}>
            <div className={cx('panel')}>
              <div className={cx('closeButton')} onClick={this.closePanel}>
                <Ionicon icon="md-close" />
              </div>
              <h1>로그인 하시겠습니까?</h1>
              <p>
                로그인을 하지 않으면,
                <br />
                교환권 구매 및 선물함 이용 시<br />
                휴대폰 인증이 필요합니다.
              </p>
              <div className={cx('button')} onClick={this.moveToLogin}>
                로그인
              </div>
              <div className={cx('message')} onClick={this.moveToGiftshop}>
                비로그인 상태로 이용할래요.
              </div>
              <div className={cx('guide')}>
                클럽아티제 회원이 되시면 구매 금액 적립 등<br />
                다양한 혜택을 누리실 수 있습니다.
              </div>
            </div>
          </div>
        )}
      </header>
    );
  }

  toggleMenu = isOpen => {
    this.setState({
      isOpen,
    });
  };

  _handleScroll = () => {
    this.setState({
      isScroll: document.documentElement.scrollTop > 0,
    });
  };

  _onClick = to => {
    navigate(to);
    this.setState({ isOpen: false });
  };

  _onClickGiftshop = () => {
    this.confirmLoggedIn();
  };

  confirmLoggedIn = () => {
    if (this.state.isLoggedIn) {
      const token = sessionStorage.getItem('access_token');
      window.open(`${PAYCO_GIFTSHOP_URL}/home?token=${token}`);
    } else {
      this.setState({ showPanel: true });
    }
  };

  closePanel = () => {
    this.setState({ showPanel: false });
  };

  moveToLogin = () => {
    this.closePanel();
    window.open('/popup/login/', 'loginPopup', 'width=560,height=776');
  };

  moveToGiftshop = () => {
    this.closePanel();
    window.open(`${PAYCO_GIFTSHOP_URL}/home`);
  };
}

export default Desktop;
