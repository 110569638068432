import { createActions } from 'redux-actions';

const fetchActions = {
  REQUEST: undefined,
  SUCCESS: response => ({ data: response }),
  FAILURE: error => ({ error }),
};

const fetchWithParamActions = {
  REQUEST: params => ({ data: params }),
  SUCCESS: response => ({ data: response }),
  FAILURE: error => ({ error }),
};

const fetchAndClearActions = {
  REQUEST: id => ({ id }),
  SUCCESS: response => ({ data: response }),
  FAILURE: error => ({ error }),
  CLEAR: undefined,
};

const fetchAndClearWithParamActions = {
  REQUEST: params => ({ data: params }),
  SUCCESS: response => ({ data: response }),
  FAILURE: error => ({ error }),
  CLEAR: undefined,
};

const saveActions = {
  REQUEST: data => ({ data }),
  SUCCESS: response => ({ data: response }),
  FAILURE: error => ({ error }),
};

export const recentActions = createActions(fetchActions, { prefix: 'RECENT' });

export const noticeActions = createActions(fetchActions, { prefix: 'NOTICE' });

export const storeActions = createActions(fetchActions, { prefix: 'STORE' });

export const faqActions = createActions(fetchActions, { prefix: 'FAQ' });

export const newMenuActions = createActions(fetchAndClearActions, { prefix: 'NEW_MENU' });

export const newMenuListActions = createActions(fetchActions, { prefix: 'NEW_MENU_LIST' });

export const eventActions = createActions(fetchAndClearActions, { prefix: 'EVENT' });

export const eventListActions = createActions(fetchActions, { prefix: 'EVENT_LIST' });

export const userActions = createActions(fetchWithParamActions, { prefix: 'USER' });

export const pointActions = createActions(fetchWithParamActions, { prefix: 'POINT' });

export const pointListActions = createActions(fetchAndClearWithParamActions, { prefix: 'POINT_LIST' });

export const stampHoldListActions = createActions(fetchAndClearWithParamActions, { prefix: 'STAMP_HOLD_LIST' });

export const stampTradeListActions = createActions(fetchAndClearWithParamActions, { prefix: 'STAMP_TRADE_LIST' });

export const couponHoldListActions = createActions(fetchAndClearWithParamActions, { prefix: 'COUPON_HOLD_LIST' });

export const couponUseListActions = createActions(fetchAndClearWithParamActions, { prefix: 'COUPON_USE_LIST' });

export const cardOwnerListActions = createActions(
  {
    ...fetchWithParamActions,
    CHANGE_NAME: params => ({ data: params }),
    CHANGE_STATUS: params => ({ data: params }),
  },
  { prefix: 'CARD_OWNER_LIST' },
);

export const cardTradeListActions = createActions(fetchAndClearWithParamActions, { prefix: 'CARD_TRADE_LIST' });

export const suggestActions = createActions(saveActions, { prefix: 'SUGGEST' });

export const vocActions = createActions(saveActions, { prefix: 'VOC' });
