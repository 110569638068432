import { takeEvery, put, call } from 'redux-saga/effects';

import { recentActions, noticeActions, newMenuActions, newMenuListActions, eventActions, eventListActions } from '../actions';
import { get, API_URI } from 'api';


function* fetchRecent(action) {
  try {
    const response = yield call(() => get(API_URI.RECENT), action.payload);

    yield put(recentActions.success(response));
  } catch (e) {
    yield put(recentActions.failure(e.status));
  }
}

function* fetchNotice(action) {
  try {
    const response = yield call(() => get(API_URI.NOTICE), action.payload);

    yield put(noticeActions.success(response));
  } catch (e) {
    yield put(noticeActions.failure(e.status));
  }
}

function* fetchNewMenu(action) {
  try {
    const response = yield call(({ id }) => get(`${API_URI.NEW_MENU}/${id}`), action.payload);

    yield put(newMenuActions.success(response));
  } catch (e) {
    yield put(newMenuActions.failure(e.status));
  }
}

function* fetchNewMenuList(action) {
  try {
    const response = yield call(() => get(API_URI.NEW_MENU), action.payload);

    yield put(newMenuListActions.success(response));
  } catch (e) {
    yield put(newMenuListActions.failure(e.status));
  }
}

function* fetchEvent(action) {
  try {
    const response = yield call(({ id }) => get(`${API_URI.EVENT}/${id}`), action.payload);

    yield put(eventActions.success(response));
  } catch (e) {
    yield put(eventActions.failure(e.status));
  }
}

function* fetchEventList(action) {
  try {
    const response = yield call(() => get(API_URI.EVENT), action.payload);

    yield put(eventListActions.success(response));
  } catch (e) {
    yield put(eventListActions.failure(e.status));
  }
}

export default function* root() {
  yield takeEvery('RECENT/REQUEST', fetchRecent);
  yield takeEvery('NOTICE/REQUEST', fetchNotice);
  yield takeEvery('NEW_MENU/REQUEST', fetchNewMenu);
  yield takeEvery('NEW_MENU_LIST/REQUEST', fetchNewMenuList);
  yield takeEvery('EVENT/REQUEST', fetchEvent);
  yield takeEvery('EVENT_LIST/REQUEST', fetchEventList);
}