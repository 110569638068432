import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames/bind';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import styles from './Mobile.module.scss';

const cx = classNames.bind(styles);
library.add(fab);

const Mobile = () => (
  <footer className={cx('bottom')}>
    <div className={cx('container')}>
      <div className={cx('logoWrapper')}>
        <img src={require('assets/images/logo/logo-footer.png')} alt="cafe artisée" width="192" height="19.16167" />
      </div>
      <div className={cx('menus')}>
        <Link to="/privacy/" className={cx('menu')}>
          개인정보처리방침
        </Link>
        <div className={cx('divider')} />
        <Link to="/terms-of-use/" className={cx('menu')}>
          클럽아티제이용약관
        </Link>
        <div className={cx('divider')} />
        <Link to="/email-security/" className={cx('menu')}>
          이메일무단수집거부
        </Link>
        <div className={cx('divider')} />
        <Link to="/cctv-policies/" className={cx('menu')}>
          영상처리기기운영 · 관리방침
        </Link>
        <div className={cx('divider')} />
        <Link to="/report/" className={cx('menu')}>
          경영공시
        </Link>
        <div className={cx('divider')} />
        <a
          href="https://www.kbei.org/helpline/dhflour"
          target="_blank"
          rel="noopener noreferrer"
          className={cx('menu')}>
          윤리경영
        </a>
      </div>
      <div className={cx('infoWrapper')}>
        <div className={cx('info')}>㈜보나비 대표이사 이종민</div>
        <div className={cx('info')}>서울시 강남구 테헤란로28길 32</div>
        <div className={cx('info')}>사업자등록번호 220-87-92541</div>
        <div className={cx('info')}>대표번호 02-2155-5777</div>
      </div>
      <div className={cx('copyright')}>2019 cafe artisée, all rights reserved.</div>
      <div className={cx('icons')}>
        <a
          href="https://www.instagram.com/cafeartisee/"
          target="_blank"
          rel="noopener noreferrer"
          className={cx('instagram')}>
          &nbsp;
        </a>
        <a
          href="https://www.facebook.com/cafeartisee"
          target="_blank"
          rel="noopener noreferrer"
          className={cx('facebook')}>
          &nbsp;
        </a>
        <a
          href="https://bot-api.closer.ai/extension/kakaobiz/@보나비고객센터"
          target="_blank"
          rel="noopener noreferrer"
          className={cx('kakao')}>
          &nbsp;
        </a>
      </div>
    </div>
  </footer>
);

export default Mobile;
