import { takeEvery, put, call } from 'redux-saga/effects';

import { faqActions } from '../actions';
import { get, API_URI } from 'api';


function* fetchFaq(action) {
  try {
    const response = yield call(() => get(API_URI.FAQ), action.payload);

    yield put(faqActions.success(response));
  } catch (e) {
    yield put(faqActions.failure(e.status));
  }
}

export default function* root() {
  yield takeEvery('FAQ/REQUEST', fetchFaq);
}