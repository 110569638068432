import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'react-grid-system';
import classNames from 'classnames/bind';

import styles from './NoticeBar.module.scss';
import { noticeActions } from '../../actions';

const cx = classNames.bind(styles);

library.add(faTimes);

class NoticeBar extends Component {

  componentDidMount() {
    this.props.actions.noticeActions.request();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure } = nextProps.noticeList;
    return !isProcessing && !failure;
  }

  render() {
    const { noticeList, onClose } = this.props;
    const newestNotice = noticeList.data.length > 0 ? noticeList.data[0] : null;

    if (newestNotice) {
      return (
        <div className={cx('container')}>
          <Container>
            <Row>
              <Col xs={12} className={cx('wrapper')}>
                <div className={cx('label')}>NOTICE</div>
                <div className={cx('title')} onClick={() => this.moveToNotice(newestNotice.id)}>{newestNotice.title}</div>
                <div className={cx('close')} onClick={onClose}><FontAwesomeIcon icon="times" color="white" /></div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      return null;
    }
  }

  moveToNotice = (id) => {
    navigate(`/news/notice/?${id}`);
    this.props.onClose();
  }
}


/**
 * Define redux settings
 */
const mapStateToProps = (state) => {
  const { noticeList } = state;
  return {
    noticeList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      noticeActions: bindActionCreators(noticeActions, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoticeBar);