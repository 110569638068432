import React, { Component } from 'react';
import classNames from 'classnames/bind';

import NoticeBar from './NoticeBar';
import Header from './Header';
import Floating from './Floating';
import Footer from './Footer';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowNoticeBar: false,
    };
  }

  render() {
    const { isShowNoticeBar } = this.state;
    const { children } = this.props;

    return (
      <div className={cx({ padding: isShowNoticeBar })}>
        {isShowNoticeBar && <NoticeBar onClose={this.closeNoticeBar} />}

        <Header />
        <Floating />
        {children}
        <Footer />
      </div>
    );
  }

  closeNoticeBar = () => {
    this.setState({
      isShowNoticeBar: false,
    });
  };
}

export default Page;
