import { handleActions } from 'redux-actions';

const initialState = {
  isProcessing: false,
  failure: false,
  data: {},
};

export default handleActions(
  {
    'MEMBER/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'MEMBER/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'MEMBER/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
  },
  initialState
);