import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { setConfiguration } from 'react-grid-system';

import Page from './Page';
import './layout.css';

// react-grid-system Configuration
// https://www.npmjs.com/package/react-grid-system#configuration
setConfiguration({
  breakpoints:     [576, 768, 992, 1200],
  containerWidths: [540, 750, 960, 1140],
  gutterWidth: 8,
});

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            slogan
            url
          }
        }
      }
    `}
    render={data => (
      <div>
        <Helmet
          title={data.site.siteMetadata.title}
          defaultTitle={data.site.siteMetadata.title}
          titleTemplate={`%s | ${data.site.siteMetadata.slogan}`}
          meta={[
            { name: 'og:url', content: data.site.siteMetadata.url },
            { name: 'og:title', content: data.site.siteMetadata.title },
            { name: 'og:type', content: 'website' },
            { name: 'og:description', content: data.site.siteMetadata.slogan },
            { name: 'og:image', content: require('assets/images/misc/og.jpg') },
            { name: 'og:image:width', content: '560' },
            { name: 'og:image:height', content: '280' },
            { name: 'og:image:type', content: 'image/png' },
            // TODO
            // { name: 'description', content: 'Sample' },
            // { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="ko" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.2.6/css/swiper.css"></link>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.2.6/css/swiper.min.css"></link>
        </Helmet>

        <Page>
          {children}
        </Page>
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;