// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-app-change-address-js": () => import("./../../../src/pages/callback/app/change-address.js" /* webpackChunkName: "component---src-pages-callback-app-change-address-js" */),
  "component---src-pages-callback-app-change-cellphone-js": () => import("./../../../src/pages/callback/app/change-cellphone.js" /* webpackChunkName: "component---src-pages-callback-app-change-cellphone-js" */),
  "component---src-pages-callback-app-change-password-js": () => import("./../../../src/pages/callback/app/change-password.js" /* webpackChunkName: "component---src-pages-callback-app-change-password-js" */),
  "component---src-pages-callback-app-join-js": () => import("./../../../src/pages/callback/app/join.js" /* webpackChunkName: "component---src-pages-callback-app-join-js" */),
  "component---src-pages-callback-app-login-js": () => import("./../../../src/pages/callback/app/login.js" /* webpackChunkName: "component---src-pages-callback-app-login-js" */),
  "component---src-pages-callback-app-query-js": () => import("./../../../src/pages/callback/app/query.js" /* webpackChunkName: "component---src-pages-callback-app-query-js" */),
  "component---src-pages-callback-web-change-address-js": () => import("./../../../src/pages/callback/web/change-address.js" /* webpackChunkName: "component---src-pages-callback-web-change-address-js" */),
  "component---src-pages-callback-web-change-cellphone-js": () => import("./../../../src/pages/callback/web/change-cellphone.js" /* webpackChunkName: "component---src-pages-callback-web-change-cellphone-js" */),
  "component---src-pages-callback-web-change-password-js": () => import("./../../../src/pages/callback/web/change-password.js" /* webpackChunkName: "component---src-pages-callback-web-change-password-js" */),
  "component---src-pages-callback-web-join-js": () => import("./../../../src/pages/callback/web/join.js" /* webpackChunkName: "component---src-pages-callback-web-join-js" */),
  "component---src-pages-callback-web-login-js": () => import("./../../../src/pages/callback/web/login.js" /* webpackChunkName: "component---src-pages-callback-web-login-js" */),
  "component---src-pages-callback-web-query-js": () => import("./../../../src/pages/callback/web/query.js" /* webpackChunkName: "component---src-pages-callback-web-query-js" */),
  "component---src-pages-callback-web-withdraw-js": () => import("./../../../src/pages/callback/web/withdraw.js" /* webpackChunkName: "component---src-pages-callback-web-withdraw-js" */),
  "component---src-pages-cctv-policies-js": () => import("./../../../src/pages/cctv-policies.js" /* webpackChunkName: "component---src-pages-cctv-policies-js" */),
  "component---src-pages-club-artisee-faq-js": () => import("./../../../src/pages/club-artisee/faq.js" /* webpackChunkName: "component---src-pages-club-artisee-faq-js" */),
  "component---src-pages-club-artisee-membership-js": () => import("./../../../src/pages/club-artisee/membership.js" /* webpackChunkName: "component---src-pages-club-artisee-membership-js" */),
  "component---src-pages-club-artisee-my-artisee-js": () => import("./../../../src/pages/club-artisee/my-artisee.js" /* webpackChunkName: "component---src-pages-club-artisee-my-artisee-js" */),
  "component---src-pages-club-artisee-my-page-js": () => import("./../../../src/pages/club-artisee/my-page.js" /* webpackChunkName: "component---src-pages-club-artisee-my-page-js" */),
  "component---src-pages-contact-order-js": () => import("./../../../src/pages/contact/order.js" /* webpackChunkName: "component---src-pages-contact-order-js" */),
  "component---src-pages-contact-recruit-js": () => import("./../../../src/pages/contact/recruit.js" /* webpackChunkName: "component---src-pages-contact-recruit-js" */),
  "component---src-pages-contact-suggest-js": () => import("./../../../src/pages/contact/suggest.js" /* webpackChunkName: "component---src-pages-contact-suggest-js" */),
  "component---src-pages-contact-voc-js": () => import("./../../../src/pages/contact/voc.js" /* webpackChunkName: "component---src-pages-contact-voc-js" */),
  "component---src-pages-email-security-js": () => import("./../../../src/pages/email-security.js" /* webpackChunkName: "component---src-pages-email-security-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-bakery-js": () => import("./../../../src/pages/menu/bakery.js" /* webpackChunkName: "component---src-pages-menu-bakery-js" */),
  "component---src-pages-menu-beverage-js": () => import("./../../../src/pages/menu/beverage.js" /* webpackChunkName: "component---src-pages-menu-beverage-js" */),
  "component---src-pages-menu-dessert-js": () => import("./../../../src/pages/menu/dessert.js" /* webpackChunkName: "component---src-pages-menu-dessert-js" */),
  "component---src-pages-menu-food-js": () => import("./../../../src/pages/menu/food.js" /* webpackChunkName: "component---src-pages-menu-food-js" */),
  "component---src-pages-menu-retail-js": () => import("./../../../src/pages/menu/retail.js" /* webpackChunkName: "component---src-pages-menu-retail-js" */),
  "component---src-pages-news-event-index-js": () => import("./../../../src/pages/news/event/index.js" /* webpackChunkName: "component---src-pages-news-event-index-js" */),
  "component---src-pages-news-event-view-js": () => import("./../../../src/pages/news/event/view.js" /* webpackChunkName: "component---src-pages-news-event-view-js" */),
  "component---src-pages-news-new-index-js": () => import("./../../../src/pages/news/new/index.js" /* webpackChunkName: "component---src-pages-news-new-index-js" */),
  "component---src-pages-news-new-view-js": () => import("./../../../src/pages/news/new/view.js" /* webpackChunkName: "component---src-pages-news-new-view-js" */),
  "component---src-pages-news-notice-js": () => import("./../../../src/pages/news/notice.js" /* webpackChunkName: "component---src-pages-news-notice-js" */),
  "component---src-pages-popup-join-js": () => import("./../../../src/pages/popup/join.js" /* webpackChunkName: "component---src-pages-popup-join-js" */),
  "component---src-pages-popup-login-js": () => import("./../../../src/pages/popup/login.js" /* webpackChunkName: "component---src-pages-popup-login-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-the-story-brand-story-js": () => import("./../../../src/pages/the-story/brand-story.js" /* webpackChunkName: "component---src-pages-the-story-brand-story-js" */),
  "component---src-pages-the-story-coffee-story-js": () => import("./../../../src/pages/the-story/coffee-story.js" /* webpackChunkName: "component---src-pages-the-story-coffee-story-js" */)
}

