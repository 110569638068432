import { takeEvery, put, call } from 'redux-saga/effects';

import { suggestActions, vocActions } from '../actions';
import { post, postFormData, API_URI } from '../api';

function* saveSuggest(action) {
  try {
    const response = yield call(({ data }) => postFormData(API_URI.SUGGEST, data), action.payload);

    yield put(suggestActions.success(response));
  } catch (e) {
    yield put(suggestActions.failure(e.status));
  }
}

function* saveVoc(action) {
  try {
    const response = yield call(({ data }) => postFormData(API_URI.VOC, data), action.payload);

    yield put(vocActions.success(response));
  } catch (e) {
    yield put(vocActions.failure(e.status));
  }
}

export default function* root() {
  yield takeEvery('SUGGEST/REQUEST', saveSuggest);
  yield takeEvery('VOC/REQUEST', saveVoc);
}
