import { combineReducers } from 'redux';

import { recent, newMenu, newMenuList, event, eventList, noticeList } from './news';
import store from './store';
import faq from './faq';
import { suggest, voc } from './contact';
import member from './member';
import {
  user,
  point,
  pointList,
  stampHoldList,
  stampTradeList,
  couponHoldList,
  couponUseList,
  cardOwnerList,
  cardTradeList,
} from './membership';

export default combineReducers({
  recent,
  newMenu,
  newMenuList,
  event,
  eventList,
  noticeList,
  store,
  faq,
  suggest,
  voc,
  member,
  user,
  point,
  pointList,
  stampHoldList,
  stampTradeList,
  couponHoldList,
  couponUseList,
  cardOwnerList,
  cardTradeList,
});
