import { handleActions } from 'redux-actions';

const itemInitialState = {
  isProcessing: false,
  failure: false,
  data: {},
};

const listInitialState = {
  isProcessing: false,
  failure: false,
  data: [],
};

export const recent = handleActions(
  {
    'RECENT/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'RECENT/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'RECENT/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
  },
  listInitialState
);

export const newMenu = handleActions(
  {
    'NEW_MENU/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'NEW_MENU/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'NEW_MENU/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
    'NEW_MENU/CLEAR': (state, action) => {
      return itemInitialState;
    }
  },
  itemInitialState
);

export const newMenuList = handleActions(
  {
    'NEW_MENU_LIST/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'NEW_MENU_LIST/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'NEW_MENU_LIST/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
  },
  listInitialState
);

export const event = handleActions(
  {
    'EVENT/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'EVENT/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'EVENT/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
    'EVENT/CLEAR': (state, action) => {
      return itemInitialState;
    }
  },
  itemInitialState
);

export const eventList = handleActions(
  {
    'EVENT_LIST/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'EVENT_LIST/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'EVENT_LIST/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
  },
  listInitialState
);

export const noticeList = handleActions(
  {
    'NOTICE/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'NOTICE/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'NOTICE/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
  },
  listInitialState
);