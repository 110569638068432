import { handleActions } from 'redux-actions';

const initialState = {
  isProcessing: false,
  failure: false,
  data: {},
};

export const suggest = handleActions(
  {
    'SUGGEST/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'SUGGEST/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'SUGGEST/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
  },
  initialState,
);

export const voc = handleActions(
  {
    'VOC/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'VOC/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'VOC/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
  },
  initialState,
);
