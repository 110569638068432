import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';
import { BrowserView, MobileView } from 'react-device-detect';
import { ScreenClassRender } from 'react-grid-system';

import styles from './Floating.module.scss';

const cx = classNames.bind(styles);

export default class Floating extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowQRCode: false,
      location: null,
    };
  }

  componentDidMount() {
    this.setState({
      location: window.location,
    });
  }

  render() {
    const { isShowQRCode, location } = this.state;
    const isForMenu = location !== null ? location.pathname.startsWith('/menu') : false;

    return (
      <ScreenClassRender render={(screenClass) => {
        const isMobile = ['xs'].includes(screenClass);

        return (
          <div className={cx('container')}>
            <div className={cx(['buttons', { mobile: isMobile }])}>
              {/* QR code */}
              {isForMenu && (
                <div>
                  <BrowserView>
                    <div className={cx(['button', 'giftButton', { show: isShowQRCode }])} onClick={this.toggleQRCode}>
                      {isShowQRCode && (
                        <div>
                          <div className={cx('title')}>소중한 분에게<br />아티제를 선물하세요.</div>
                          <img src={require('assets/images/misc/qrcode.png')} alt="선물하기" width="136" height="136" />
                        </div>
                      )}
                      {!isShowQRCode && (
                        <img src={require('assets/images/misc/gift.png')} alt="선물하기" width="40" height="40" />
                      )}
                    </div>
                  </BrowserView>
                  <MobileView>
                    <a href="http://bit.ly/2QDLGww" target="_blank" rel="noopener noreferrer" className={cx(['button', 'giftButton', { show: isShowQRCode }])}>
                      <img src={require('assets/images/misc/gift.png')} alt="선물하기" width="40" height="40" />
                    </a>
                  </MobileView>
                </div>
              )}

              {/* Scroll to top */}
              <div className={cx(['button', 'toTopButton'])} onClick={this.scrollToTop}>
                <Ionicon icon="ios-arrow-up" fontSize="16px" color="#fdfaeb" />
                <div className={cx('buttonText')}>TOP</div>
              </div>
            </div>
          </div>
        );
      }} />
    );
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  toggleQRCode = () => {
    this.setState({
      isShowQRCode: !this.state.isShowQRCode,
    });
  }
}