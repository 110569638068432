import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { navigate, Link } from 'gatsby';
import Ionicon from 'react-ionicons';

import styles from './Mobile.module.scss';
import { PAYCO_GIFTSHOP_URL } from '../../../config';
import { post } from '../../../api';

const cx = classNames.bind(styles);

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScroll: false,
      isShowOverlay: false,
      isLoggedIn: false,
      memberNo: null,
      showPanel: false,
    };

    this._handleScroll = this._handleScroll.bind(this);
  }

  componentDidMount() {
    const token = sessionStorage.getItem('access_token');

    // 엑세스 토큰 있는 경우
    if (token !== null) {
      post('member/me/sign', { token }).then(result => {
        if (result.isSuccess) {
          this.setState({
            isLoggedIn: true,
            memberNo: result.data.idNo,
          });
        }
      });
    }

    window.addEventListener('scroll', this._handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll);
  }

  render() {
    const { isScroll, isShowOverlay } = this.state;
    const { menus, isTransparent } = this.props;

    const logoImage =
      isTransparent && !isScroll
        ? require('assets/images/logo/logo-white.png')
        : require('assets/images/logo/logo-black.png');
    const menuIconColor = isTransparent && !isScroll ? 'white' : 'black';

    return (
      <div>
        <header className={cx(['navbar', { transparent: isTransparent && !isScroll, white: isScroll }])}>
          <Link to="/" className={cx('logoWrapper')}>
            <img src={logoImage} alt="logo" className={cx('logo')} />
          </Link>
          <div className={cx('menuIcon')} onClick={this.toggleOverlay.bind(this, true)}>
            <Ionicon icon="md-menu" color={menuIconColor} />
          </div>
        </header>
        <div className={cx(['overlay', { show: isShowOverlay }])} onClick={this.toggleOverlay.bind(this, false)}>
          <div className={cx('closeIconWrapper')}>
            <div className={cx('closeIcon')}>
              <Ionicon icon="md-close" color="white" />
            </div>
          </div>
        </div>
        <div className={cx(['sidePanel', { show: isShowOverlay }])}>
          <div className={cx('topBar')}>
            <img src={require('assets/images/logo/logo-white.png')} alt="logo" className={cx('logo')} />
          </div>
          <div className={cx('scroll')}>
            {menus.map((menu, i) => {
              return (
                <div key={i} className={cx('menu')}>
                  <div className={cx('title')}>{menu.title}</div>
                  {menu.submenus.map((submenu, j) => {
                    if (submenu.newWindow) {
                      return (
                        <a
                          key={j}
                          className={cx('submenu')}
                          href={submenu.uri}
                          target="_blank"
                          rel="noopener noreferrer">
                          {submenu.title}
                        </a>
                      );
                    } else if (submenu.uri) {
                      return (
                        <div
                          key={j}
                          to={submenu.uri}
                          onClick={this._onClick.bind(this, submenu.uri)}
                          className={cx('submenu')}>
                          {submenu.title}
                        </div>
                      );
                    } else if (submenu.title === '선물하기') {
                      return (
                        <div key={j} className={cx('submenu')} onClick={this._onClickGiftshop.bind(this)}>
                          {submenu.title}
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
          </div>
        </div>
        {this.state.showPanel && (
          <div className={cx('loginOverlay')}>
            <div className={cx('panel')}>
              <div className={cx('closeButton')} onClick={this.closePanel}>
                <Ionicon icon="md-close" />
              </div>
              <h1>로그인 하시겠습니까?</h1>
              <p>
                로그인을 하지 않으면,
                <br />
                교환권 구매 및 선물함 이용 시<br />
                휴대폰 인증이 필요합니다.
              </p>
              <div className={cx('button')} onClick={this.moveToLogin}>
                로그인
              </div>
              <div className={cx('message')} onClick={this.moveToGiftshop}>
                비로그인 상태로 이용할래요.
              </div>
              <div className={cx('guide')}>
                클럽아티제 회원이 되시면 구매 금액 적립 등<br />
                다양한 혜택을 누리실 수 있습니다.
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  toggleOverlay = isShowOverlay => {
    this.setState({ isShowOverlay });
  };

  _handleScroll = () => {
    this.setState({ isScroll: window.scrollY > 0 });
  };

  _onClick = to => {
    navigate(to);
    this.setState({ isShowOverlay: false });
  };

  _onClickGiftshop = () => {
    this.confirmLoggedIn();
  };

  confirmLoggedIn = () => {
    if (this.state.isLoggedIn) {
      const token = sessionStorage.getItem('access_token');
      window.open(`${PAYCO_GIFTSHOP_URL}/home?token=${token}`);
    } else {
      this.setState({
        isShowOverlay: false,
        showPanel: true,
      });
    }
  };

  closePanel = () => {
    this.setState({ showPanel: false });
  };

  moveToLogin = () => {
    this.closePanel();
    window.open('/popup/login/', 'loginPopup', 'width=560,height=776');
  };

  moveToGiftshop = () => {
    this.closePanel();
    window.open(`${PAYCO_GIFTSHOP_URL}/home`);
  };
}

export default Mobile;
