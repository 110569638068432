import React from 'react';
import { Visible, Hidden } from 'react-grid-system';

import Desktop from './Desktop';
import Mobile from './Mobile';

const Footer = () => (
  <div>
    {/* Desktop */}
    <Visible lg xl>
      <Desktop />
    </Visible>

    {/* Mobile */}
    <Hidden lg xl>
      <Mobile />
    </Hidden>
  </div>
);

export default Footer;