/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { Provider } from 'react-redux';

import createStore from './src/state/createStore';
import Layout from './src/components/layout';

const store = createStore();

export const wrapPageElement = ({ element, props }) => {
  // '/callback'으로 시작하는 path의 페이지는 레이아웃 제외한다.
  const isExceptLayout = props.location.pathname.startsWith('/callback') || props.location.pathname.startsWith('/popup');

  const wrapper = isExceptLayout ? element : (
    <Layout {...props}>
      {element}
    </Layout>
  );

  return wrapper;
}

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      {element}
    </Provider>
  );
}