import { handleActions } from 'redux-actions';

const initialState = {
  isProcessing: false,
  failure: false,
  data: {},
};

export const user = handleActions(
  {
    'USER/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'USER/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'USER/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
  },
  initialState
);

export const point = handleActions(
  {
    'POINT/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'POINT/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'POINT/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
  },
  initialState
);

export const pointList = handleActions(
  {
    'POINT_LIST/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'POINT_LIST/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'POINT_LIST/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
    'POINT_LIST/CLEAR': (state, action) => initialState,
  },
  initialState
);

export const stampHoldList = handleActions(
  {
    'STAMP_HOLD_LIST/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'STAMP_HOLD_LIST/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'STAMP_HOLD_LIST/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
    'STAMP_HOLD_LIST/CLEAR': (state, action) => initialState,
  },
  initialState
);

export const stampTradeList = handleActions(
  {
    'STAMP_TRADE_LIST/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'STAMP_TRADE_LIST/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'STAMP_TRADE_LIST/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
    'STAMP_TRADE_LIST/CLEAR': (state, action) => initialState,
  },
  initialState
);

export const couponHoldList = handleActions(
  {
    'COUPON_HOLD_LIST/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'COUPON_HOLD_LIST/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'COUPON_HOLD_LIST/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
    'COUPON_HOLD_LIST/CLEAR': (state, action) => initialState,
  },
  initialState
);

export const couponUseList = handleActions(
  {
    'COUPON_USE_LIST/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'COUPON_USE_LIST/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'COUPON_USE_LIST/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
    'COUPON_USE_LIST/CLEAR': (state, action) => initialState,
  },
  initialState
);

export const cardOwnerList = handleActions(
  {
    'CARD_OWNER_LIST/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'CARD_OWNER_LIST/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'CARD_OWNER_LIST/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
    'CARD_OWNER_LIST/CHANGE_NAME': (state, action) => {
      const { cardNo, newNickName } = action.payload.data;

      state.data.result.some(element => {
        if (element.cardNo === cardNo) {
          element.nickNm = newNickName;
          return true;
        }

        return false;
      });

      return Object.assign({}, state);
    },
    'CARD_OWNER_LIST/CHANGE_STATUS': (state, action) => {
      const { cardNo, toBeStatus, pauseReason } = action.payload.data;

      state.data.result.some(element => {
        if (element.cardNo === cardNo) {
          element.cardStatusCode = toBeStatus;
          element.pauseReasonCode = toBeStatus === 'USE' ? null : pauseReason;
          return true;
        }

        return false;
      });

      return Object.assign({}, state);
    },
  },
  initialState
);

export const cardTradeList = handleActions(
  {
    'CARD_TRADE_LIST/REQUEST': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: true,
      });
    },
    'CARD_TRADE_LIST/SUCCESS': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: false,
        data: action.payload.data,
      });
    },
    'CARD_TRADE_LIST/FAILURE': (state, action) => {
      return Object.assign({}, state, {
        isProcessing: false,
        failure: true,
      });
    },
    'CARD_TRADE_LIST/CLEAR': (state, action) => initialState,
  },
  initialState
);