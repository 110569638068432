import { fork } from 'redux-saga/effects';

import news from './news';
import store from './store';
import faq from './faq';
import contact from './contact';
import membership from './membership';

// Consider using takeEvery
export default function* root() {
  yield fork(news);
  yield fork(store);
  yield fork(faq);
  yield fork(contact);
  yield fork(membership);
}
