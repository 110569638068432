import { takeEvery, put, call } from 'redux-saga/effects';

import { storeActions } from '../actions';
import { get, API_URI } from 'api';


function* fetchStore(action) {
  try {
    const response = yield call(() => get(API_URI.STORE), action.payload);

    yield put(storeActions.success(response));
  } catch (e) {
    yield put(storeActions.failure(e.status));
  }
}

export default function* root() {
  yield takeEvery('STORE/REQUEST', fetchStore);
}