import { takeEvery, put, call } from 'redux-saga/effects';

import { userActions, pointActions, pointListActions, stampHoldListActions, stampTradeListActions, couponHoldListActions, couponUseListActions, cardOwnerListActions, cardTradeListActions } from '../actions';
import { post, API_URI } from 'api';


function* fetchUser(action) {
  try {
    const response = yield call(({ data }) => post(`${API_URI.USER}/getInfo`, data), action.payload);

    yield put(userActions.success(response));
  } catch (e) {
    yield put(userActions.failure(e.status));
  }
}

function* fetchPoint(action) {
  try {
    const response = yield call(({ data }) => post(`${API_URI.POINT}/getPointBalance`, data), action.payload);

    yield put(pointActions.success(response));
  } catch (e) {
    yield put(pointActions.failure(e.status));
  }
}

function* fetchPointList(action) {
  try {
    const response = yield call(({ data }) => post(`${API_URI.POINT}/getTradeList`, data), action.payload);

    yield put(pointListActions.success(response));
  } catch (e) {
    yield put(pointListActions.failure(e.status));
  }
}

function* fetchCardOwnerList(action) {
  try {
    const response = yield call(({ data }) => post(`${API_URI.CARD}/getOwnerList`, data), action.payload);

    yield put(cardOwnerListActions.success(response));
  } catch (e) {
    yield put(cardOwnerListActions.failure(e.status));
  }
}

function* fetchCardTradeList(action) {
  try {
    const response = yield call(({ data }) => post(`${API_URI.CARD}/getTradeList`, data), action.payload);

    yield put(cardTradeListActions.success(response));
  } catch (e) {
    yield put(cardTradeListActions.failure(e.status));
  }
}

function* fetchStampHoldList(action) {
  try {
    const response = yield call(({ data }) => post(`${API_URI.STAMP}/getHoldList`, data), action.payload);

    yield put(stampHoldListActions.success(response));
  } catch (e) {
    yield put(stampHoldListActions.failure(e.status));
  }
}

function* fetchStampTradeList(action) {
  try {
    const response = yield call(({ data }) => post(`${API_URI.STAMP}/getTradeList`, data), action.payload);

    yield put(stampTradeListActions.success(response));
  } catch (e) {
    yield put(stampTradeListActions.failure(e.status));
  }
}

function* fetchCouponHoldList(action) {
  try {
    const response = yield call(({ data }) => post(`${API_URI.COUPON}/getHoldList`, data), action.payload);

    yield put(couponHoldListActions.success(response));
  } catch (e) {
    yield put(couponHoldListActions.failure(e.status));
  }
}

function* fetchCouponUseList(action) {
  try {
    const response = yield call(({ data }) => post(`${API_URI.COUPON}/getUseList`, data), action.payload);

    yield put(couponUseListActions.success(response));
  } catch (e) {
    yield put(couponUseListActions.failure(e.status));
  }
}

export default function* root() {
  yield takeEvery('USER/REQUEST', fetchUser);
  yield takeEvery('POINT/REQUEST', fetchPoint);
  yield takeEvery('POINT_LIST/REQUEST', fetchPointList);
  yield takeEvery('STAMP_HOLD_LIST/REQUEST', fetchStampHoldList);
  yield takeEvery('STAMP_TRADE_LIST/REQUEST', fetchStampTradeList);
  yield takeEvery('COUPON_HOLD_LIST/REQUEST', fetchCouponHoldList);
  yield takeEvery('COUPON_USE_LIST/REQUEST', fetchCouponUseList);
  yield takeEvery('CARD_OWNER_LIST/REQUEST', fetchCardOwnerList);
  yield takeEvery('CARD_TRADE_LIST/REQUEST', fetchCardTradeList);
}