import React, { Component } from 'react';
import { Visible, Hidden } from 'react-grid-system';

import Desktop from './Desktop';
import Mobile from './Mobile';
import menus from '../../../datas/menu.json';

const lightHeaderPaths = [
  '/club-artisee/membership/',
  '/club-artisee/my-artisee/',
  '/club-artisee/my-page/',
  '/club-artisee/faq/',
  '/the-story/brand-story/',
  '/the-story/coffee-story/',
  '/menu/bakery/',
  '/menu/beverage/',
  '/menu/dessert/',
  '/menu/food/',
  '/menu/retail/',
  '/news/new/',
  '/news/event/',
  '/news/notice/',
  '/store/',
  '/contact/suggest/',
  '/contact/voc/',
  '/contact/recruit/',
  '/privacy/',
  '/terms-of-use/',
  '/email-security/',
  '/cctv-policies/',
  '/report/',
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
    };
  }

  componentDidMount() {
    this.setState({
      location: window.location,
    });
  }

  render() {
    const { location } = this.state;
    const pathname = location !== null ? location.pathname : null;

    return (
      <div>
        {/* Desktop */}
        <Visible lg xl>
          <Desktop menus={menus} isTransparent={this.isLightHeaderPath(pathname)} />
        </Visible>

        {/* Mobile */}
        <Hidden lg xl>
          <Mobile menus={menus} isTransparent={this.isLightHeaderPath(pathname)} />
        </Hidden>
      </div>
    );
  }

  isLightHeaderPath = pathname => {
    if (pathname === null) {
      return false;
    }

    return lightHeaderPaths.some(element => {
      if (pathname.endsWith('index.html')) {
        pathname = pathname.substring(0, pathname.length - 10);
      } else if (!pathname.endsWith('/')) {
        pathname += '/';
      }

      return pathname === element;
    });
  };
}

export default Header;
