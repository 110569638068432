const IS_PRODUCTION = true;

export const __DEV__ = !IS_PRODUCTION;

export const API_URL = IS_PRODUCTION
  ? 'https://cafeartisee.apis.flyground.co.kr'
  : 'https://alpha-cafeartisee.apis.flyground.co.kr';

export const ARTISEE_ID_URL = IS_PRODUCTION ? 'https://id.cafeartisee.com' : 'https://alpha-id.cafeartisee.com';

export const PAYCO_GIFTSHOP_URL = IS_PRODUCTION
  ? 'https://giftshop-channeling.payco.com/artisee'
  : 'https://alpha-giftshop-channeling.payco.com/artisee';
