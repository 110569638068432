import React, { Component } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames/bind';
import { Container, Row, Col } from 'react-grid-system';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import styles from './Desktop.module.scss';

const cx = classNames.bind(styles);
library.add(fab);

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
    };
  }

  componentDidMount() {
    this.setState({
      token: sessionStorage.getItem('access_token'),
    });
  }

  render() {
    const { token } = this.state;

    return (
      <footer className={cx('footer')}>
        {/* 로그인 전인 경우 */}
        {token === null && (
          <div className={cx('bar')}>
            <Container>
              <Row>
                <Col xs={3} className={cx('cell')}>
                  <div className={cx('question')}>아직 아티제 멤버가 아니신가요?</div>
                </Col>
                <Col xs={7} className={cx('cell')}>
                  <div className={cx('message')}>지금 클럽아티제 멤버가 되시고 다양한 혜택을 받으세요!</div>
                </Col>
                <Col xs={2} className={cx('rightCell')}>
                  <div className={cx('quickLink')} onClick={this.join}>
                    멤버십 가입하기
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

        {/* Footer */}
        <Container className={cx('container')}>
          <Row>
            <Col xs={3}>
              <img src={require('assets/images/logo/logo-footer.png')} alt="a piece of art" width="220" />
            </Col>
            <Col xs={7}>
              <div className={cx('menus')}>
                <Link to="/privacy/" className={cx('menu')}>
                  개인정보처리방침
                </Link>
                <div className={cx('divider')} />
                <Link to="/terms-of-use/" className={cx('menu')}>
                  클럽아티제이용약관
                </Link>
                <div className={cx('divider')} />
                <Link to="/email-security/" className={cx('menu')}>
                  이메일무단수집거부
                </Link>
                <div className={cx('divider')} />
                <Link to="/cctv-policies/" className={cx('menu')}>
                  영상처리기기운영 · 관리방침
                </Link>
                <div className={cx('divider')} />
                <Link to="/report/" className={cx('menu')}>
                  경영공시
                </Link>
                <div className={cx('divider')} />
                <a
                  href="https://www.kbei.org/helpline/dhflour"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={cx('menu')}>
                  윤리경영
                </a>
              </div>
              <div className={cx('address')}>
                ㈜보나비 대표이사 이종민 · 서울시 강남구 테헤란로28길 32 · 사업자등록번호 220-87-92541 · 대표번호
                02-2155-5777
              </div>
              <div className={cx('copyright')}>2019 cafe artisée, all rights reserved.</div>
            </Col>
            <Col xs={2}>
              <div className={cx('icons')}>
                <a
                  href="https://www.instagram.com/cafeartisee/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={cx('instagram')}>
                  &nbsp;
                </a>
                <a
                  href="https://www.facebook.com/cafeartisee/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={cx('facebook')}>
                  &nbsp;
                </a>
                <a
                  href="https://bot-api.closer.ai/extension/kakaobiz/@보나비고객센터"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={cx('kakao')}>
                  &nbsp;
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }

  join = () => {
    window.open('/popup/join/', 'joinPopup', 'width=600,height=700');
  };
}

export default Desktop;
