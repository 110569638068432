import { createStore, applyMiddleware, compose } from 'redux';
import { compact } from 'lodash';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import reducer from 'reducers';
import sagas from 'sagas';
import { __DEV__ } from '../config';

const store = () => {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = compact([sagaMiddleware, __DEV__ ? createLogger() : null]);

  // mount it on the Store
  const store = createStore(reducer, {}, compose(applyMiddleware(...middlewares)));

  // then run the saga
  sagaMiddleware.run(sagas);

  return store;
};

export default store;
